<div class="container-content">
  <xng-breadcrumb [separator]="separatorIconTemplate">
    <ng-container
      *xngBreadcrumbItem="
        let breadcrumb;
        let info = info;
        let first = first
      "
    >
      <mat-icon *ngIf="info">{{ info }}</mat-icon>
      <ng-container *ngIf="!first">
        {{ breadcrumb | translate }}
      </ng-container>
    </ng-container>
  </xng-breadcrumb>

  <ng-template #separatorIconTemplate>
    <mat-icon>arrow_right</mat-icon>
  </ng-template>

  <h2>{{ 'title.booking-summary' | translate }}</h2>

  <div class="flex-row" *ngIf="!isLoading">
    <div class="flex-1 flex-column">
      <div *ngIf="booking">
        <img
          *ngIf="booking.test.logoPath"
          [src]="booking.test.logoPath"
          [alt]="booking.test.name"
          class="logo"
        />
        <div
          class="booking-data"
          [class.incomplete-booking]="booking.status === 'canceled' || (booking.status === 'open' && (booking.paymentExpectedUntil || !booking.requestedPayment))"
          [class.deleted-booking]="booking.deleted || booking.status === 'order_canceled'"
        >
          <p>
            {{ booking.test.name }}
          </p>
          <p>
          <span>
            {{
            'booking.default.summary.format.dayOfWeek'
              | translate
              : {
                timeSlot:
                  timeSlot | localizedDate: 'EEEE'
              }
            }}
            <strong>
              <time [dateTime]="timeSlotDate">{{
              'booking.default.summary.format.date'
                | translate
                : {
                  timeSlot:
                    timeSlot
                      | localizedDate: 'mediumDate'
                }
              }}</time>
            </strong>
          </span>
            <br />
            <span>{{
              'booking.default.summary.format.area.prefix'
                | translate
              }}</span>
            <strong>{{ examEvent.testarea }}</strong>
            <span>{{
              'booking.default.summary.format.area.suffix'
                | translate
              }}</span>
            <span>{{
              'booking.default.summary.format.time.prefix'
                | translate
              }}</span>
            <strong>
              <time [dateTime]="timeSlotTime">{{
              'booking.default.summary.format.time.format'
                | translate
                : {
                  timeSlot:
                    timeSlot | localizedDate: 'shortTime'
                }
              }}</time>
            </strong>
          </p>
          <p *ngIf="booking.requestedPayment">
            {{ 'label.test-event-price' | translate }}:
            <strong>{{
              booking.requestedPayment.amount
                | currency
                : examEvent.price.currency
                  : undefined
                  : undefined
                  : currentLanguage
              }}</strong>
          </p>
          <p
            *ngIf="!paymentCouldArriveShortly"
            class="booking-status-wrapper"
          >
            <span
              class="booking-status"
            >
              {{ 'label.payment-status' | translate }}:
              <span>
                <strong>
                  {{
                  'booking.default.status.' + booking.status
                    | lowercase
                    | translate
                  }}
                </strong>
              </span>
            </span>
            <button
              *ngIf="isAdmin && booking.status !== 'paid' && booking.status !== 'not_required' && booking.status !== 'order_canceled'"
              mat-icon-button
              class="booking-status-edit"
              [matTooltip]="'booking.default.summary.edit-status' | translate"
              (click)="changePaymentStatusDialog()"
            >
              <mat-icon
              aria-hidden="false"
                [attr.aria-label]="'booking.default.summary.edit-status' | translate"
              >mode_edit</mat-icon>
            </button>
          </p>

          <!-- TODO We should probably use a loading indicator similar to the one we use in the LoadingIndicatorFullComponent -->
          <mat-progress-spinner
            *ngIf="paymentCouldArriveShortly"
            mode="indeterminate"
          ></mat-progress-spinner>
        </div>

        <div
          class="section-bg-warning"
          *ngIf="canBePaidFor"
        >
          <div class="section-bg-warning-title">
            <strong>
              <mat-icon>warning</mat-icon>
              {{
              'booking.default.summary.booking-without-payment.title'
                | translate
              }}
            </strong>
          </div>

          <ng-container *ngIf="tenant !== 'sdv'">
            {{
              'booking.default.summary.booking-without-payment.held-until'
                | translate
                  : {
                      reservedUntil:
                        automaticCancelDate
                        | localizedDate: 'long'
                    }
            }}
          </ng-container>

          <ng-container *ngIf="tenant === 'sdv'">
            <p>
              <b>{{
                  'booking.sdv.summary.booking-without-payment.held-until.card-info.part-1'
                    | translate
                }}</b>
              {{
                'booking.sdv.summary.booking-without-payment.held-until.card-info.part-2'
                  | translate
              }}
            </p>
            <p>
              <b>{{
                'booking.sdv.summary.booking-without-payment.held-until.sepa-info.part-1'
                  | translate
              }}</b>
              {{
                'booking.sdv.summary.booking-without-payment.held-until.sepa-info.part-2'
                  | translate
              }}
              <b><u>{{
                'booking.sdv.summary.booking-without-payment.held-until.sepa-info.part-3'
                  | translate
              }}</u></b>
            </p>
          </ng-container>
        </div>

        <button
          mat-raised-button
          color="primary"
          class="payment-button"
          *ngIf="canBePaidFor"
          (click)="doPayment()"
        >
          {{ 'button.pay-now' | translate }}
        </button>

        <div
          class="infotext"
          *ngIf="infotextBlocks.length > 0"
        >
          <ng-container *ngFor="let block of infotextBlocks">
            <pre *ngIf="!blockStartsWithDot(block)" class="infotext-space"> </pre>
            <ng-container [ngSwitch]="block.type">
              <h2
                *ngSwitchCase="'HEADING'"
                class="infotext-heading"
              >{{ block.value }}</h2>
              <span
                *ngSwitchCase="'TEXT'"
                class="infotext-text"
                [class.infotext-bold]="isBoldInfotext(block)"
                [class.infotext-italic]="isItalicInfotext(block)"
                [class.infotext-underlined]="isUnderlinedInfotext(block)"
              >{{ block.value }}</span>
              <a
                *ngSwitchCase="'LINK'"
                class="infotext-link"
                [class.infotext-bold]="isBoldInfotext(block)"
                [class.infotext-italic]="isItalicInfotext(block)"
                [class.infotext-underlined]="isUnderlinedInfotext(block)"
                [href]="getLinkFromLinkBlock(block)"
              >{{ block.value }}</a>
              <br
                *ngSwitchCase="'LINE_BREAK'"
                class="infotext-linebreak"
              />
            </ng-container>
          </ng-container>
        </div>

        <div class="section-booking-history" *ngIf="isAdmin">
          <div class="section-booking-history-title">
            <strong>{{ 'booking.default.summary.history-events.titles.title' | translate }}</strong>
          </div>

          <app-exam-booking-history-table
            [bookingId]="booking.bookingId"
          >
          </app-exam-booking-history-table>
        </div>
      </div>

      <section *ngIf="tenant === 'sdv'">
        <app-add-questions-answers-overview
          [booking]="booking"
        ></app-add-questions-answers-overview>
      </section>

      <section *ngIf="tenant === 'sdv' && isOptionalQuestionAnswered">
        <app-optional-questions-answers-overview
          [booking]="booking"
        ></app-optional-questions-answers-overview>
      </section>
    </div>

    <ng-container [ngSwitch]="tenant">
      <div
        *ngSwitchCase="'default'"
        class="flex-1 flex-column"
      >
        <ng-container
          [ngSwitch]="booking.test.templateName"
        >
          <ng-container *ngSwitchCase="'PhaST'">
            <div class="question-block">
              <div class="section-bg-contrast">
                <span>
                  {{
                  'phast.question.university.text'
                    | translate
                  }}
                </span>
              </div>
              <ul>
                <li
                  *ngFor="
                    let uni of userAnswers.universityIds
                  "
                >
                  {{ uni.translationKey | translate }}
                </li>
              </ul>
            </div>
            <div class="question-block">
              <div class="section-bg-contrast">
                <span>{{
                  'phast.question.final-grade.text'
                    | translate
                  }}</span>
              </div>
              <div class="plain-text-output">
                {{
                userAnswers.finalGrade
                  ? userAnswers.finalGrade
                  : ('label.not-specified' | translate)
                }}
              </div>
            </div>
            <!-- TODO Without functionality yet
          <button mat-raised-button>
          {{ 'button.edit' | translate }}
        </button> -->
          </ng-container>

          <ng-container *ngSwitchCase="'TM-WISO'">
            <div class="question-block">
              <div class="section-bg-contrast">
                <span>{{
                  'tm-wiso.questions.language.text'
                    | translate
                  }}</span>
              </div>
              <div class="plain-text-output">
                <span>{{
                  userAnswers.language
                    ? (userAnswers.language | translate)
                    : ('label.not-specified' | translate)
                  }}</span>
              </div>
            </div>

            <div class="question-block">
              <div class="section-bg-contrast">
                <span>{{
                  'tm-wiso.questions.apply-for-university.text'
                    | translate
                  }}</span>
              </div>
              <ul>
                <li
                  *ngFor="
                    let uni of userAnswers.universityIds
                  "
                >
                  {{ uni.translationKey | translate }}
                </li>
              </ul>
            </div>

            <div class="question-block">
              <div class="section-bg-contrast">
                <span>{{
                  'tm-wiso.questions.send-results.text'
                    | translate
                  }}</span>
              </div>
              <ul>
                <li
                  *ngFor="
                    let uni of userAnswers.sendResultsToUniversityIds
                  "
                >
                  {{ uni.translationKey | translate }}
                </li>
              </ul>
            </div>
          </ng-container>

          <ng-container *ngSwitchCase="'GSAT'">
            <div class="question-block">
              <div class="section-bg-contrast">
                <span>{{
                  'gsat.questions.language.text' | translate
                  }}</span>
              </div>
              <div class="plain-text-output">
                <span>{{
                  userAnswers.language
                    ? (userAnswers.language | translate)
                    : ('label.not-specified' | translate)
                  }}</span>
              </div>
            </div>
          </ng-container>

          <ng-container *ngSwitchCase="'ITB-Business'">
            <div class="question-block">
              <div class="section-bg-contrast">
                <span>{{
                  'itb-business.questions.language.text'
                    | translate
                  }}</span>
              </div>
              <div class="plain-text-output">
                <span>{{
                  userAnswers.language
                    ? (userAnswers.language | translate)
                    : ('label.not-specified' | translate)
                  }}</span>
              </div>
            </div>

            <div class="question-block">
              <div class="section-bg-contrast">
                <span>{{
                  'itb-business.questions.apply-for-university.text'
                    | translate
                  }}</span>
              </div>
              <ul>
                <li
                  *ngFor="
                    let uni of userAnswers.universityIds
                  "
                >
                  {{ uni.translationKey | translate }}
                </li>
              </ul>
            </div>

            <div class="question-block">
              <div class="section-bg-contrast">
                <span>{{
                    'itb-business.questions.send-results.text'
                      | translate
                  }}</span>
              </div>
              <ul>
                <li
                  *ngFor="
                    let uni of userAnswers.sendResultsToUniversityIds
                  "
                >
                  {{ uni.translationKey | translate }}
                </li>
              </ul>
            </div>

            <div class="question-block">
              <div class="section-bg-contrast">
                <span>{{
                  'itb-business.questions.final-grade.text'
                    | translate
                  }}</span>
              </div>
              <div class="plain-text-output">
                {{
                userAnswers.finalGrade
                  ? userAnswers.finalGrade
                  : ('label.not-specified' | translate)
                }}
              </div>
            </div>
          </ng-container>

          <ng-container *ngSwitchCase="'ITB-Science'">
            <div class="question-block">
              <div class="section-bg-contrast">
                <span>{{
                    'itb-science.questions.language.text'
                      | translate
                  }}</span>
              </div>
              <div class="plain-text-output">
                <span>{{
                    userAnswers.language
                      ? (userAnswers.language | translate)
                      : ('label.not-specified' | translate)
                  }}</span>
              </div>
            </div>

            <div class="question-block">
              <div class="section-bg-contrast">
                <span>{{
                    'itb-science.questions.final-grade.text'
                      | translate
                  }}</span>
              </div>
              <div class="plain-text-output">
                {{
                  userAnswers.finalGrade
                    ? userAnswers.finalGrade
                    : ('label.not-specified' | translate)
                }}
              </div>
            </div>
          </ng-container>

          <ng-container *ngSwitchCase="'ITB-Technology'">
            <div class="question-block">
              <div class="section-bg-contrast">
                <span>{{
                    'itb-technology.questions.language.text'
                      | translate
                  }}</span>
              </div>
              <div class="plain-text-output">
                <span>{{
                    userAnswers.language
                      ? (userAnswers.language | translate)
                      : ('label.not-specified' | translate)
                  }}</span>
              </div>
            </div>

            <div class="question-block">
              <div class="section-bg-contrast">
                <span>{{
                    'itb-technology.questions.final-grade.text'
                      | translate
                  }}</span>
              </div>
              <div class="plain-text-output">
                {{
                  userAnswers.finalGrade
                    ? userAnswers.finalGrade
                    : ('label.not-specified' | translate)
                }}
              </div>
            </div>
          </ng-container>
        </ng-container>
      </div>
    </ng-container>

    <div
      [class.flex-1]="tenant === 'default'"
      class="flex-column"
    >
      <div class="section-bg-contrast">
        <div class="section-bg-contrast-title">
          <strong>{{
            'title.downloads' | translate
            }}</strong>
        </div>

        <ng-container *ngIf="isAdmin">
          <ngx-file-drop
            class="booking-file-upload"
            (onFileDrop)="uploadFile($event)"
            (onFileOver)="setHoveringOverUpload(true)"
            (onFileLeave)="setHoveringOverUpload(false)"
            [directory]="false"
            [dropZoneLabel]="
              (isUploading
                ? 'file-upload.files-uploading'
                : 'file-upload.drop-files-here'
              ) | translate
            "
            [dropZoneClassName]="
              isHoveringOverUpload
                ? 'file-upload-drop-zone hovering-over-upload'
                : 'file-upload-drop-zone'
            "
            contentClassName="file-upload-content"
            [showBrowseBtn]="true"
            browseBtnClassName="file-upload-browse-btn mat-focus-indicator section-bg-contrast-btn mat-raised-button mat-button-base"
            [browseBtnLabel]="
              'file-upload.browse-button-label' | translate
            "
            [multiple]="true"
          ></ngx-file-drop>

          <hr class="file-upload-separator" />
        </ng-container>

        <span *ngIf="bookingFiles === undefined">
          Die Dateien für diese Buchung werden geladen...
        </span>

        <span *ngIf="bookingFiles?.length === 0">
          Es stehen aktuell keine Downloads für diese
          Buchung zur Verfügung.
        </span>

        <div
          *ngFor="let bookingFile of bookingFiles"
          class="section-bg-contrast-list-entity"
        >
          <span
          >{{ bookingFile.fileName }} ({{
            fileSize(bookingFile)
            }})</span
          >
          <div class="booking-file-buttons">
            <button
              mat-icon-button
              [matTooltip]="'file-download.download-button' | translate: { fileName: bookingFile.fileName }"
              (click)="
                downloadFile(
                  bookingFile.internalFileId,
                  bookingFile.fileName
                )
              "
              [title]="'file-download.download-button' | translate: { fileName: bookingFile.fileName }"
            >
              <mat-icon
                aria-hidden="false"
                [attr.aria-label]="'file-download.download-button' | translate: { fileName: bookingFile.fileName }"
              >
                download
              </mat-icon>
            </button>
            <button
              mat-icon-button
              [matTooltip]="'file-download.delete-button' | translate: { fileName: bookingFile.fileName }"
              (click)="deleteFile(bookingFile)"
              *ngIf="isAdmin"
              [title]="'file-download.delete-button' | translate: { fileName: bookingFile.fileName }"
            >
              <mat-icon
                aria-hidden="false"
                [attr.aria-label]="'file-download.delete-button' | translate: { fileName: bookingFile.fileName }"
              >
                delete
              </mat-icon>
            </button>
          </div>
        </div>
      </div>

      <div class="section-bg-contrast" *ngIf="isAdmin">
        <div class="section-bg-contrast-title">
          <strong>{{
            'title.booking-change-options' | translate
            }}</strong>
        </div>
        <button
          class="section-bg-contrast-btn"
          mat-raised-button
          [attr.aria-label]="'button.booking-change' | translate"
          (click)="changeBookingDialog()"
        >
          {{ 'button.booking-change' | translate }}
        </button>
        <button
          class="section-bg-contrast-btn"
          mat-raised-button
          color="warn"
          [attr.aria-label]="'button.booking-cancel' | translate"
          (click)="cancelBookingDialog()"
          [disabled]="booking.status === 'order_canceled'"
        >
          {{ 'button.booking-cancel' | translate }}
        </button>
      </div>
    </div>
  </div>

  <app-loading-indicator-full
    *ngIf="isLoading"
  ></app-loading-indicator-full>
</div>
