import { DateTime } from 'luxon';

export default class Tools {
  static dateToIso(
    date: Date | string,
    defaultIfDateInvalid: string = ''
  ): string {
    if (date instanceof Date) {
      return date.toISOString();
    } else {
      return (
        DateTime.fromISO(date).toISO() ??
        defaultIfDateInvalid
      );
    }
  }

  static dateFromIso(isoDateString: string): Date {
    return new Date(isoDateString.split('[')[0]);
  }
}
