import { HttpErrorResponse } from '@angular/common/http';
import {
  Component,
  EventEmitter,
  HostBinding,
  OnInit,
  Output,
} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NGXLogger } from 'ngx-logger';
import {
  BookingService,
  NotificationService,
} from 'src/app/services';
import { RadioOption, SelectOption } from 'src/app/types';
import {
  FormOfStudies,
  TypeOfSchool,
} from 'src/app/types/response';

@Component({
  selector: 'app-step2-requirements-check-form-sdv',
  templateUrl:
    './step2-requirements-check-form-sdv.component.html',
  styleUrls: [
    './step2-requirements-check-form-sdv.component.scss',
  ],
})
export class Step2RequirementsCheckFormSdvComponent
  implements OnInit
{
  @HostBinding('class.form-component') hostClass = true;
  @Output()
  requirementsCheckFormValid: EventEmitter<boolean> =
    new EventEmitter<boolean>();
  @Output() nextStep: EventEmitter<boolean> =
    new EventEmitter<boolean>();

  form!: FormGroup;

  radioBtnFormOfStudiesOptions: RadioOption[] = [
    {
      label:
        'booking.sdv.prerequisites-check-page.formOfStudies.radio-options.full-time',
      value: FormOfStudies.FullTime,
    },
    {
      label:
        'booking.sdv.prerequisites-check-page.formOfStudies.radio-options.part-time',
      value: FormOfStudies.PartTime,
    },
  ];

  radioBtnYesNoOptions: RadioOption[] = [
    {
      label:
        'booking.sdv.prerequisites-check-page.radio-btn.options.yes',
      value: true,
    },
    {
      label:
        'booking.sdv.prerequisites-check-page.radio-btn.options.no',
      value: false,
    },
  ];

  typeOfSchoolOptions: SelectOption[] = [
    {
      value: TypeOfSchool.University,
      label:
        'booking.sdv.prerequisites-check-page.typeOfSchool.select-options.' +
        TypeOfSchool.University,
    },
    {
      value: TypeOfSchool.TechnicalCollege,
      label:
        'booking.sdv.prerequisites-check-page.typeOfSchool.select-options.' +
        TypeOfSchool.TechnicalCollege,
    },
    {
      value: TypeOfSchool.DualStudiesTechnicalCollege,
      label:
        'booking.sdv.prerequisites-check-page.typeOfSchool.select-options.' +
        TypeOfSchool.DualStudiesTechnicalCollege,
    },
    {
      value: TypeOfSchool.DualStudiesUniversity,
      label:
        'booking.sdv.prerequisites-check-page.typeOfSchool.select-options.' +
        TypeOfSchool.DualStudiesUniversity,
    },
    {
      value: TypeOfSchool.ArtCollege,
      label:
        'booking.sdv.prerequisites-check-page.typeOfSchool.select-options.' +
        TypeOfSchool.ArtCollege,
    },
    {
      value: TypeOfSchool.Conservatory,
      label:
        'booking.sdv.prerequisites-check-page.typeOfSchool.select-options.' +
        TypeOfSchool.Conservatory,
    },
  ];

  private prerequisitesAreFulfilled = false;

  constructor(
    private activatedRoute: ActivatedRoute,
    private bookingService: BookingService,
    private formBuilder: FormBuilder,
    private logger: NGXLogger,
    private notificationService: NotificationService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      enrolledInFirstOrSecondSemester: [
        null,
        Validators.required,
      ],
      hasGermanNationality: [null, Validators.required],
      studiedInEuOrSwitzerland: [null, Validators.required],
      permittedToReceiveBafoeg: [null, Validators.required],
      studiesPrimarilyInGermany: [
        null,
        Validators.required,
      ],
      typeOfSchool: [null, Validators.required],
      formOfStudies: [null, Validators.required],
      academicStudiesCompleted: [null, Validators.required],
      suggestedForSdV: [null, Validators.required],
    });
  }

  get enrolledInFirstOrSecondSemesterControl(): FormControl<
    boolean | null
  > {
    return this.form.get(
      'enrolledInFirstOrSecondSemester'
    ) as FormControl<boolean | null>;
  }

  get hasGermanNationalityControl(): FormControl<
    boolean | null
  > {
    return this.form.get(
      'hasGermanNationality'
    ) as FormControl<boolean | null>;
  }

  get studiedInEuOrSwitzerlandControl(): FormControl<
    boolean | null
  > {
    return this.form.get(
      'studiedInEuOrSwitzerland'
    ) as FormControl<boolean | null>;
  }

  get permittedToReceiveBafoegControl(): FormControl<
    boolean | null
  > {
    return this.form.get(
      'permittedToReceiveBafoeg'
    ) as FormControl<boolean | null>;
  }

  get studiesPrimarilyInGermanyControl(): FormControl<
    boolean | null
  > {
    return this.form.get(
      'studiesPrimarilyInGermany'
    ) as FormControl<boolean | null>;
  }

  get typeOfSchoolControl(): FormControl<TypeOfSchool | null> {
    return this.form.get(
      'typeOfSchool'
    ) as FormControl<TypeOfSchool | null>;
  }

  get formOfStudiesControl(): FormControl<FormOfStudies | null> {
    return this.form.get(
      'formOfStudies'
    ) as FormControl<FormOfStudies | null>;
  }

  get academicStudiesCompletedControl(): FormControl<
    boolean | null
  > {
    return this.form.get(
      'academicStudiesCompleted'
    ) as FormControl<boolean | null>;
  }

  get suggestedForSdVControl(): FormControl<
    boolean | null
  > {
    return this.form.get('suggestedForSdV') as FormControl<
      boolean | null
    >;
  }

  get hasGermanNationalityVisible(): boolean | null {
    return (
      this.enrolledInFirstOrSecondSemesterControl.value ===
      true
    );
  }

  get isStudiedInEuOrSwitzerlandVisible(): boolean | null {
    return (
      this.hasGermanNationalityVisible &&
      this.hasGermanNationalityControl.value === true
    );
  }

  get isPermittedToReceiveBafoegVisible(): boolean | null {
    return (
      this.hasGermanNationalityVisible &&
      this.hasGermanNationalityControl.value === false
    );
  }

  get isStudiesPrimarilyInGermanyVisible(): boolean | null {
    return (
      this.isPermittedToReceiveBafoegVisible &&
      this.permittedToReceiveBafoegControl.value === true
    );
  }

  get isTypeOfSchoolVisible(): boolean | null {
    return (
      (this.isStudiesPrimarilyInGermanyVisible &&
        this.studiesPrimarilyInGermanyControl.value ===
          true) ||
      (this.isStudiedInEuOrSwitzerlandVisible &&
        this.studiedInEuOrSwitzerlandControl.value === true)
    );
  }

  get isFormOfStudiesVisible(): boolean | null {
    return (
      this.isTypeOfSchoolVisible &&
      (this.typeOfSchoolControl.value ===
        TypeOfSchool.University ||
        this.typeOfSchoolControl.value ===
          TypeOfSchool.TechnicalCollege ||
        this.typeOfSchoolControl.value ===
          TypeOfSchool.DualStudiesTechnicalCollege)
    );
  }

  get isAcademicStudiesCompletedVisible(): boolean | null {
    return (
      this.isFormOfStudiesVisible &&
      this.formOfStudiesControl.value ===
        FormOfStudies.FullTime
    );
  }

  get isSuggestedForSdVVisible(): boolean | null {
    return (
      this.isAcademicStudiesCompletedVisible &&
      this.academicStudiesCompletedControl.value === false
    );
  }

  get isNextEnabled(): boolean | null {
    return (
      this.isSuggestedForSdVVisible &&
      this.prerequisitesAreFulfilled
    );
  }

  submit(): void {
    this.bookingService.formState.item.prerequisiteQuestionData =
      this.form.value;
    this.nextStep.emit(!!this.prerequisitesAreFulfilled);
  }

  checkPrerequisites(): void {
    this.bookingService.formState.item =
      BookingService.factoryItem();

    this.bookingService
      .checkPrerequisitesForBooking(
        this.examIdParam,
        this.form.value
      )
      .then((response) => {
        this.prerequisitesAreFulfilled =
          response.prerequisitesAreFulfilled;

        this.requirementsCheckFormValid.emit(
          this.prerequisitesAreFulfilled
        );
      })
      .catch((e) => {
        this.fail(e);
      });
  }

  resetCheckPrerequisitesTrigger(): void {
    this.prerequisitesAreFulfilled = false;
    this.suggestedForSdVControl.setValue(null);
  }

  async cancelBooking(): Promise<void> {
    if (this.bookingId > 0) {
      await this.bookingService
        .deleteBooking(this.bookingId)
        .finally(() => {
          this.bookingService.formState = {
            loading: false,
            item: BookingService.factoryItem(),
          };
        });
    }
    this.router.navigate(['/home']);
  }

  private get bookingId(): number {
    return this.bookingService.formState.item.bookingId;
  }

  private get examIdParam(): number {
    return Number(
      this.activatedRoute.snapshot.paramMap
        .get('id')
        ?.match(/^\d+/i)
    );
  }

  private fail(
    error: HttpErrorResponse
  ): Promise<undefined> {
    this.logger.error(
      'Step2RequirementsCheckFormSdvComponent:checkPrerequisitesError',
      {
        error,
      }
    );

    const errorDialog =
      this.notificationService.httpError(error);
    return errorDialog.finally(() => Promise.reject(error));
  }
}
