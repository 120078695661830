import { SelectionModel } from '@angular/cdk/collections';
import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import {
  MatSort,
  MatSortable,
} from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { TranslateService } from '@ngx-translate/core';
import { DateTime } from 'luxon';
import { NGXLogger } from 'ngx-logger';
import {
  ExamEventService,
  NotificationService,
  StatisticsSearchFilters,
} from '../../../services';
import { ExamEventStatistics } from '../../../types/ExamEventStatistics';

export interface SelectedEventInfos {
  selectedEventIds: number[];
  events: ExamEventStatistics[];
}

@Component({
  selector: 'app-exam-event-statistics-table',
  templateUrl:
    './exam-event-statistics-table.component.html',
  styleUrls: [
    './exam-event-statistics-table.component.scss',
  ],
})
export class ExamEventStatisticsTableComponent
  implements OnInit, AfterViewInit
{
  @ViewChild(MatSort) sort!: MatSort;

  @Input()
  currentTestEventId?: number;

  @Input()
  searchFilters!: StatisticsSearchFilters;

  @Input()
  allowMultiSelect = true;

  @Output()
  activeEventSet = new EventEmitter<number>();

  @Output()
  eventChosen = new EventEmitter<number>();

  @Output()
  eventsSelectedForMultiAction =
    new EventEmitter<SelectedEventInfos>();

  oldEventStatistics!: ExamEventStatistics;
  newEventStatistics!: ExamEventStatistics;
  activeRowId!: number;
  eventStatistics: ExamEventStatistics[] = [];
  dataSource = new MatTableDataSource(this.eventStatistics);

  get rowsConfig(): string[] {
    if (this.allowMultiSelect) {
      return [
        'selected',
        'id',
        'testarea',
        'eventdate',
        'eventtime',
        'testcenter',
        'maxCapacity',
        'numberOfBookings',
        'utilizationRatio',
      ];
    } else {
      return [
        'id',
        'testarea',
        'eventdate',
        'eventtime',
        'testcenter',
        'maxCapacity',
        'numberOfBookings',
        'utilizationRatio',
      ];
    }
  }

  // Allow selecting test events in the table
  private initialTestEventSelection: ExamEventStatistics[] =
    [];
  selectedTestEvents!: SelectionModel<ExamEventStatistics>;

  constructor(
    private examEventService: ExamEventService,
    private logger: NGXLogger,
    private notification: NotificationService,
    private translateService: TranslateService,
    public dialog: MatDialog
  ) {}

  async ngOnInit(): Promise<void> {
    this.initialTestEventSelection = [];
    this.selectedTestEvents =
      new SelectionModel<ExamEventStatistics>(
        true,
        this.initialTestEventSelection
      );
    this.selectedTestEvents.changed.subscribe(() => {
      const selectedEventIds =
        this.selectedTestEvents.selected.map(
          ({ testEventId }) => testEventId
        );
      this.eventsSelectedForMultiAction.emit({
        selectedEventIds,
        events: this.eventStatistics,
      });
    });

    await this.loadAvailableEvents();

    if (this.currentTestEventId) {
      this.activeRowId = this.currentTestEventId;
    }

    this.sort.sort({
      id: 'eventdate',
      start: 'asc',
    } as MatSortable);
  }

  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
    this.dataSource.sortingDataAccessor = (
      data: ExamEventStatistics,
      sortHeaderId: string
    ) => {
      switch (sortHeaderId) {
        case 'id':
          return data.testEventId;
        case 'testarea':
          return data.testareaName;
        case 'eventdate':
          return data.testEventTime;
        case 'eventtime':
          return DateTime.fromISO(
            data.testEventTime
          ).toLocaleString(DateTime.TIME_24_SIMPLE);
        case 'testcenter':
          return data.testCenterName;
        case 'maxCapacity':
          return data.maximumCapacity;
        case 'numberOfBookings':
          return data.numberOfBookings;
        case 'utilizationRatio':
          return data.utilizationRatio;
        default:
          return '';
      }
    };
  }

  async loadAvailableEvents(): Promise<void> {
    const statistics =
      await this.examEventService.loadStatisticsList(
        this.searchFilters
      );
    this.eventStatistics = statistics.items;

    this.dataSource.data = this.eventStatistics;

    await this.setActiveEvent();
  }

  async setActiveEvent(): Promise<void> {
    if (!this.currentTestEventId) {
      return;
    }

    const activeEvent: ExamEventStatistics | undefined =
      this.eventStatistics?.find(
        ({ testEventId }) =>
          testEventId === this.currentTestEventId
      );

    if (activeEvent) {
      this.oldEventStatistics = activeEvent;
      this.activeEventSet.emit(
        this.oldEventStatistics.testEventId
      );
    } else {
      this.logger.error(
        `The current test event ${this.currentTestEventId} could not be found.`
      );
      this.dialog.closeAll();
      await this.notification.error('error.general');
    }
  }

  newEventChosen(event: ExamEventStatistics): void {
    this.activeRowId = event.testEventId;
    this.newEventStatistics = event;
    this.eventChosen.emit(event.testEventId);
  }

  get currentLanguage(): string {
    return this.translateService.currentLang;
  }

  areAllTestEventsSelected() {
    const numSelected =
      this.selectedTestEvents.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  toggleAllTestEventsSelected() {
    if (this.areAllTestEventsSelected()) {
      this.selectedTestEvents.clear();
    } else {
      this.dataSource.data.forEach((row) =>
        this.selectedTestEvents.select(row)
      );
    }
  }
}
