import { HttpErrorResponse } from '@angular/common/http';
import {
  Component,
  EventEmitter,
  OnInit,
  Output,
} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { NGXLogger } from 'ngx-logger';
import {
  BookingService,
  CountryService,
  NotificationService,
} from 'src/app/services';
import { RadioOption, SelectOption } from 'src/app/types';
import {
  AdditionalQuestionData,
  LanguageLevel,
} from 'src/app/types/AdditionalQuestionData';

@Component({
  selector: 'app-step6-additional-questions-form-sdv',
  templateUrl:
    './step6-additional-questions-form-sdv.component.html',
  styleUrls: [
    './step6-additional-questions-form-sdv.component.scss',
  ],
})
export class Step6AdditionalQuestionsFormSdvComponent
  implements OnInit
{
  @Output()
  additionalQuestionsFormValid: EventEmitter<boolean> =
    new EventEmitter<boolean>();
  @Output() nextStep: EventEmitter<boolean> =
    new EventEmitter<boolean>();

  form!: FormGroup;
  federalStateOptions: Array<{
    label: string;
    value: string;
  }> = [];

  stateKeys = [
    'bw',
    'by',
    'be',
    'bb',
    'hb',
    'hh',
    'he',
    'mv',
    'ni',
    'nw',
    'rp',
    'sl',
    'sn',
    'st',
    'sh',
    'th',
  ];

  migrationBackgroundOptions: RadioOption[] = [
    {
      label:
        'booking.sdv.additional-questions-page.studentDetails.migrationBackground.options.yes',
      value: true,
    },
    {
      label:
        'booking.sdv.additional-questions-page.studentDetails.migrationBackground.options.no',
      value: false,
    },
  ];

  germanLanguageLevelSelectOptions: SelectOption[] = [
    {
      label:
        'booking.sdv.additional-questions-page.studentDetails.germanLanguageLevel.options.native_speaker',
      value: LanguageLevel.NATIVE_SPEAKER,
    },
    {
      label:
        'booking.sdv.additional-questions-page.studentDetails.germanLanguageLevel.options.c2',
      value: LanguageLevel.C2,
    },
    {
      label:
        'booking.sdv.additional-questions-page.studentDetails.germanLanguageLevel.options.c1',
      value: LanguageLevel.C1,
    },
    {
      label:
        'booking.sdv.additional-questions-page.studentDetails.germanLanguageLevel.options.b2',
      value: LanguageLevel.B2,
    },
    {
      label:
        'booking.sdv.additional-questions-page.studentDetails.germanLanguageLevel.options.b1',
      value: LanguageLevel.B1,
    },
    {
      label:
        'booking.sdv.additional-questions-page.studentDetails.germanLanguageLevel.options.a2',
      value: LanguageLevel.A2,
    },
    {
      label:
        'booking.sdv.additional-questions-page.studentDetails.germanLanguageLevel.options.a1',
      value: LanguageLevel.A1,
    },
  ];

  constructor(
    private bookingService: BookingService,
    private countryService: CountryService,
    private formBuilder: FormBuilder,
    private logger: NGXLogger,
    private notificationService: NotificationService,
    private router: Router,
    private translate: TranslateService
  ) {}

  ngOnInit(): void {
    this.loadFederalStateOptions();

    this.form = this.formBuilder.group({
      university: ['', Validators.required],
      studiesSubject: ['', Validators.required],
      schoolName: ['', Validators.required],
      schoolZip: [
        '',
        [Validators.required, Validators.maxLength(10)],
      ],
      schoolCity: ['', Validators.required],
      schoolCountry: ['', Validators.required],
      schoolState: ['', Validators.required],
      studiesTerm: [''],
      finalGrade: [''],
      germanGrade: [''],
      germanPoints: [''],
      mathematicsGrade: [''],
      mathematicsPoints: [''],
      migrationBackground: [null],
      germanLanguageLevel: [''],
      marketingUniversityInformationalMaterials: [false],
      marketingUniversityEvent: [false],
      marketingUniversitySocialMedia: [false],
      marketingUniversityInfoMail: [false],
      marketingUniversityPersonal: [false],
      marketingSchoolInformationalMaterials: [false],
      marketingSchoolEvent: [false],
      marketingSchoolPersonal: [false],
      marketingSocialCirclesRelatives: [false],
      marketingSocialCirclesFriendsNonSponsored: [false],
      marketingSocialCirclesFriendsSponsored: [false],
      marketingSocialCirclesFriendsInSelectionProcess: [
        false,
      ],
      marketingOtherEvent: [false],
      marketingOtherSocialMediaSdV: [false],
      marketingOtherWebsiteSdV: [false],
      marketingOtherSocialMediaOther: [false],
      marketingOtherWebsiteEFellows: [false],
      marketingOtherWebsiteArbeiterkind: [false],
      marketingOtherWebsiteStipendiumPlus: [false],
      marketingOtherWebsiteMyStipendium: [false],
      marketingOtherWebsiteOther: [false],
      marketingOtherNewspaper: [false],
      marketingOtherOther: [false],
      marketingOtherOtherText: [''],
    });

    // Set timeout to avoid ExpressionChangedAfterItHasBeenCheckedError
    // TODO: Find another way to update the form in the parent component [BOTI-304]
    setTimeout(() => {
      this.additionalQuestionsFormValid.emit(
        this.form.valid
      );
    }, 0);

    this.form.valueChanges.subscribe((_value) => {
      this.additionalQuestionsFormValid.emit(
        this.form.valid
      );
    });

    this.schoolCountryControl.valueChanges.subscribe(
      (countryValue) => {
        if (countryValue === 'DEU') {
          this.schoolStateControl.setValue(null);
        } else {
          this.schoolStateControl.setValue('Ausland');
        }
      }
    );
  }

  get countryOptions(): SelectOption[] {
    return this.countryService.selectOptions;
  }

  get universityControl(): FormControl<string | null> {
    return this.form.get('university') as FormControl<
      string | null
    >;
  }

  get studiesSubjectControl(): FormControl<string | null> {
    return this.form.get('studiesSubject') as FormControl<
      string | null
    >;
  }

  get studiesTermControl(): FormControl<number | null> {
    return this.form.get('studiesTerm') as FormControl<
      number | null
    >;
  }

  get schoolNameControl(): FormControl<string | null> {
    return this.form.get('schoolName') as FormControl<
      string | null
    >;
  }

  get schoolZipControl(): FormControl<string | null> {
    return this.form.get('schoolZip') as FormControl<
      string | null
    >;
  }

  get schoolCityControl(): FormControl<string | null> {
    return this.form.get('schoolCity') as FormControl<
      string | null
    >;
  }

  get schoolCountryControl(): FormControl<string | null> {
    return this.form.get('schoolCountry') as FormControl<
      string | null
    >;
  }

  get schoolStateControl(): FormControl<string | null> {
    return this.form.get('schoolState') as FormControl<
      string | null
    >;
  }

  get finalGradeControl(): FormControl<string | null> {
    return this.form.get('finalGrade') as FormControl<
      string | null
    >;
  }

  get germanGradeControl(): FormControl<string | null> {
    return this.form.get('germanGrade') as FormControl<
      string | null
    >;
  }

  get germanPointsControl(): FormControl<number | null> {
    return this.form.get('germanPoints') as FormControl<
      number | null
    >;
  }

  get mathematicsGradeControl(): FormControl<
    string | null
  > {
    return this.form.get('mathematicsGrade') as FormControl<
      string | null
    >;
  }

  get mathematicsPointsControl(): FormControl<
    number | null
  > {
    return this.form.get(
      'mathematicsPoints'
    ) as FormControl<number | null>;
  }

  get migrationBackgroundControl(): FormControl<
    boolean | null
  > {
    return this.form.get(
      'migrationBackground'
    ) as FormControl<boolean | null>;
  }

  get germanLanguageLevelControl(): FormControl<LanguageLevel | null> {
    return this.form.get(
      'germanLanguageLevel'
    ) as FormControl<LanguageLevel | null>;
  }

  get marketingUniversityInformationalMaterials(): FormControl<
    boolean | null
  > {
    return this.form.get(
      'marketingUniversityInformationalMaterials'
    ) as FormControl<boolean | null>;
  }

  get marketingUniversityEvent(): FormControl<
    boolean | null
  > {
    return this.form.get(
      'marketingUniversityEvent'
    ) as FormControl<boolean | null>;
  }

  get marketingUniversitySocialMedia(): FormControl<
    boolean | null
  > {
    return this.form.get(
      'marketingUniversitySocialMedia'
    ) as FormControl<boolean | null>;
  }

  get marketingUniversityInfoMail(): FormControl<
    boolean | null
  > {
    return this.form.get(
      'marketingUniversityInfoMail'
    ) as FormControl<boolean | null>;
  }

  get marketingUniversityPersonal(): FormControl<
    boolean | null
  > {
    return this.form.get(
      'marketingUniversityPersonal'
    ) as FormControl<boolean | null>;
  }

  get marketingSchoolInformationalMaterials(): FormControl<
    boolean | null
  > {
    return this.form.get(
      'marketingSchoolInformationalMaterials'
    ) as FormControl<boolean | null>;
  }

  get marketingSchoolEvent(): FormControl<boolean | null> {
    return this.form.get(
      'marketingSchoolEvent'
    ) as FormControl<boolean | null>;
  }

  get marketingSchoolPersonal(): FormControl<
    boolean | null
  > {
    return this.form.get(
      'marketingSchoolPersonal'
    ) as FormControl<boolean | null>;
  }

  get marketingSocialCirclesRelatives(): FormControl<
    boolean | null
  > {
    return this.form.get(
      'marketingSocialCirclesRelatives'
    ) as FormControl<boolean | null>;
  }

  get marketingSocialCirclesFriendsNonSponsored(): FormControl<
    boolean | null
  > {
    return this.form.get(
      'marketingSocialCirclesFriendsNonSponsored'
    ) as FormControl<boolean | null>;
  }

  get marketingSocialCirclesFriendsSponsored(): FormControl<
    boolean | null
  > {
    return this.form.get(
      'marketingSocialCirclesFriendsSponsored'
    ) as FormControl<boolean | null>;
  }

  get marketingSocialCirclesFriendsInSelectionProcess(): FormControl<
    boolean | null
  > {
    return this.form.get(
      'marketingSocialCirclesFriendsInSelectionProcess'
    ) as FormControl<boolean | null>;
  }

  get marketingOtherEvent(): FormControl<boolean | null> {
    return this.form.get(
      'marketingOtherEvent'
    ) as FormControl<boolean | null>;
  }

  get marketingOtherSocialMediaSdV(): FormControl<
    boolean | null
  > {
    return this.form.get(
      'marketingOtherSocialMediaSdV'
    ) as FormControl<boolean | null>;
  }

  get marketingOtherWebsiteSdV(): FormControl<
    boolean | null
  > {
    return this.form.get(
      'marketingOtherWebsiteSdV'
    ) as FormControl<boolean | null>;
  }

  get marketingOtherSocialMediaOther(): FormControl<
    boolean | null
  > {
    return this.form.get(
      'marketingOtherSocialMediaOther'
    ) as FormControl<boolean | null>;
  }

  get marketingOtherWebsiteEFellows(): FormControl<
    boolean | null
  > {
    return this.form.get(
      'marketingOtherWebsiteEFellows'
    ) as FormControl<boolean | null>;
  }

  get marketingOtherWebsiteArbeiterkind(): FormControl<
    boolean | null
  > {
    return this.form.get(
      'marketingOtherWebsiteArbeiterkind'
    ) as FormControl<boolean | null>;
  }

  get marketingOtherWebsiteStipendiumPlus(): FormControl<
    boolean | null
  > {
    return this.form.get(
      'marketingOtherWebsiteStipendiumPlus'
    ) as FormControl<boolean | null>;
  }

  get marketingOtherWebsiteMyStipendium(): FormControl<
    boolean | null
  > {
    return this.form.get(
      'marketingOtherWebsiteMyStipendium'
    ) as FormControl<boolean | null>;
  }

  get marketingOtherWebsiteOther(): FormControl<
    boolean | null
  > {
    return this.form.get(
      'marketingOtherWebsiteOther'
    ) as FormControl<boolean | null>;
  }

  get marketingOtherNewspaper(): FormControl<
    boolean | null
  > {
    return this.form.get(
      'marketingOtherNewspaper'
    ) as FormControl<boolean | null>;
  }
  get marketingOtherOther(): FormControl<boolean | null> {
    return this.form.get(
      'marketingOtherOther'
    ) as FormControl<boolean | null>;
  }

  get marketingOtherOtherText(): FormControl<
    string | null
  > {
    return this.form.get(
      'marketingOtherOtherText'
    ) as FormControl<string | null>;
  }

  async cancelBooking(): Promise<void> {
    await this.bookingService
      .deleteBooking(this.bookingId)
      .finally(() => {
        this.bookingService.formState = {
          loading: false,
          item: BookingService.factoryItem(),
        };
      });

    this.router.navigate(['/home']);
  }

  async updateBooking(): Promise<void> {
    const updatedAdditionalQuestionData: AdditionalQuestionData =
      {
        universityDetails: {
          name: this.universityControl.value as string,
        },
        studiesDetails: {
          subject: this.studiesSubjectControl
            .value as string,
          universityTerm: this.studiesTermControl.value as
            | number
            | undefined,
        },
        schoolDetails: {
          name: this.schoolNameControl.value as string,
          zip: this.schoolZipControl.value as string,
          city: this.schoolCityControl.value as string,
          state: this.schoolStateControl.value as string,
          country: this.schoolCountryControl
            .value as string,
        },
        ...(this.finalGradeControl.value && {
          finalGrade: this.finalGradeControl.value,
        }),
        studentDetails: {
          ...(this.migrationBackgroundControl.value !==
            null && {
            migrationBackground:
              this.migrationBackgroundControl.value,
          }),
          ...(this.germanLanguageLevelControl.value && {
            germanLanguageLevel:
              this.germanLanguageLevelControl.value,
          }),

          schoolSubjects: {
            ...((this.mathematicsGradeControl.value ||
              this.mathematicsPointsControl.value) && {
              mathematics: {
                ...(this.mathematicsGradeControl.value && {
                  grade: this.mathematicsGradeControl.value,
                }),
                ...(this.mathematicsPointsControl.value && {
                  points:
                    this.mathematicsPointsControl.value,
                }),
              },
            }),
            ...((this.germanGradeControl.value ||
              this.germanPointsControl.value) && {
              german: {
                ...(this.germanGradeControl.value && {
                  grade: this.germanGradeControl.value,
                }),
                ...(this.germanPointsControl.value && {
                  points: this.germanPointsControl.value,
                }),
              },
            }),
          },
        },
        applicantMarketingDetails: {
          university: {
            informationalMaterials:
              !!this
                .marketingUniversityInformationalMaterials
                .value,
            event: !!this.marketingUniversityEvent.value,
            socialMedia:
              !!this.marketingUniversitySocialMedia.value,
            infoMail:
              !!this.marketingUniversityInfoMail.value,
            personal:
              !!this.marketingUniversityPersonal.value,
          },
          school: {
            informationalMaterials:
              !!this.marketingSchoolInformationalMaterials
                .value,
            event: !!this.marketingSchoolEvent.value,
            personal: !!this.marketingSchoolPersonal.value,
          },
          socialCircles: {
            relatives:
              !!this.marketingSocialCirclesRelatives.value,
            friendsNonSponsored:
              !!this
                .marketingSocialCirclesFriendsNonSponsored
                .value,
            friendsSponsored:
              !!this.marketingSocialCirclesFriendsSponsored
                .value,
            friendsInSelectionProcess:
              !!this
                .marketingSocialCirclesFriendsInSelectionProcess
                .value,
          },
          other: {
            event: !!this.marketingOtherEvent.value,
            socialMedia: {
              sdv: !!this.marketingOtherSocialMediaSdV
                .value,
              other:
                !!this.marketingOtherSocialMediaOther.value,
            },
            website: {
              sdv: !!this.marketingOtherWebsiteSdV.value,
              eFellows:
                !!this.marketingOtherWebsiteEFellows.value,
              arbeiterkind:
                !!this.marketingOtherWebsiteArbeiterkind
                  .value,
              stipendiumPlus:
                !!this.marketingOtherWebsiteStipendiumPlus
                  .value,
              myStipendium:
                !!this.marketingOtherWebsiteMyStipendium
                  .value,
              other:
                !!this.marketingOtherWebsiteOther.value,
            },
            newspaper: !!this.marketingOtherNewspaper.value,
            other:
              this.marketingOtherOther.value &&
              this.marketingOtherOtherText.value
                ? this.marketingOtherOtherText.value
                : undefined,
          },
        },
      };

    this.bookingService.formState.item.additionalQuestionData =
      updatedAdditionalQuestionData;

    await this.bookingService
      .updateBooking(this.bookingId)
      .catch(async (error) => {
        this.nextStep.emit(false);
        await this.fail(error);
      })
      .then(() => this.nextStep.emit(true));
  }

  private get bookingId(): number {
    return this.bookingService.formState.item.bookingId;
  }

  private fail(
    error: HttpErrorResponse
  ): Promise<undefined> {
    this.logger.error(
      'Step6AdditionalQuestionsFormSdvComponent:updateBookingError',
      {
        error,
      }
    );

    const errorDialog =
      this.notificationService.httpError(error);
    return errorDialog.finally(() => Promise.reject(error));
  }

  private loadFederalStateOptions(): void {
    this.translate
      .get(
        'booking.sdv.additional-questions-page.schoolDetails.label.state-options'
      )
      .subscribe((translations) => {
        this.federalStateOptions = this.stateKeys.map(
          (key) => ({
            label: translations[key],
            value: translations[key],
          })
        );
      });
  }
}
