import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  Output,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { SelectOption } from '../../../types';
import { FormFieldAbstractComponent } from '../form-field-abstract.component';

@Component({
  selector: 'app-form-field-select',
  templateUrl: './form-field-select.component.html',
  styleUrls: ['./form-field-select.component.scss'],
})
export class FormFieldSelectComponent
  extends FormFieldAbstractComponent
  implements OnChanges, OnDestroy
{
  @Input() options!: SelectOption[];
  @Input() hasDeselectOption = false;
  @Input() multiple = false;
  @Input() localizedDate = false;
  @Input() panelClass!: string;
  @Input() sortBy:
    | 'none'
    | 'translated-label-asc'
    | 'translated-label-desc' = 'none';
  @Output()
  selectionChange: EventEmitter<any> =
    new EventEmitter<any>();

  sortedOptions!: SelectOption[];

  private unsubOnDestroy: Subscription = new Subscription();

  constructor(private translate: TranslateService) {
    super();
    this.unsubOnDestroy.add(
      translate.onLangChange.subscribe(() => {
        this.sortedOptions.sort((a, b) =>
          this.optionsSortComparator(a, b)
        );
      })
    );
  }

  stringify(value: null | boolean | number | string) {
    return `${value}`;
  }

  ngOnChanges() {
    this.sortedOptions = [...this.options];
    this.sortedOptions.sort((a, b) =>
      this.optionsSortComparator(a, b)
    );
  }

  private optionsSortComparator(
    a: SelectOption,
    b: SelectOption
  ): number {
    if (!this.sortBy) {
      return 0;
    }
    switch (this.sortBy) {
      case 'none': {
        return 0;
      }
      case 'translated-label-asc': {
        const translatedA = this.translate.instant(
          a.label
        ) as string;
        const translatedB = this.translate.instant(
          b.label
        ) as string;
        return translatedA.localeCompare(translatedB);
      }
      case 'translated-label-desc': {
        const translatedA = this.translate.instant(
          a.label
        ) as string;
        const translatedB = this.translate.instant(
          b.label
        ) as string;
        return translatedB.localeCompare(translatedA);
      }
    }
  }

  ngOnDestroy(): void {
    this.unsubOnDestroy.unsubscribe();
  }
}
