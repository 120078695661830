import { Component, Input } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatFormFieldAppearance } from '@angular/material/form-field';

@Component({
  template: '',
})
export class FormFieldAbstractComponent {
  @Input() field!: FormControl<unknown>;
  @Input() label = '';
  @Input() placeholder = '';
  @Input() hint = '';
  //  'legacy' | 'standard' | 'fill' | 'outline'
  @Input() appearance: MatFormFieldAppearance = 'fill';
  @Input() required = false;
  @Input() disabled = false;
  @Input() errorMessages: { [key: string]: string } = {};
  errorMessagesDefault: { [key: string]: string } = {
    required: 'field.error.required',
    invalid: 'field.error.invalid',
    email: 'field.error.email.invalid',
    maxlength: 'field.error.maxlength',
    pattern: 'field.error.pattern',
  };

  get hasLabel(): boolean {
    return this.label !== '';
  }

  get hasHint(): boolean {
    return !this.isPending && '' !== this.hint;
  }

  get isPending(): boolean {
    return 'PENDING' === this.field?.status;
  }

  get error(): string {
    if (this.field?.errors) {
      if ('string' === typeof this.field.errors.custom) {
        return this.field.errors.custom;
      }

      const error: string = Object.keys(
        this.field.errors
      )[0];

      if (this.errorMessages.hasOwnProperty(error)) {
        return this.errorMessages[error];
      }

      if (this.errorMessagesDefault.hasOwnProperty(error)) {
        return this.errorMessagesDefault[error];
      }

      // eslint-disable-next-line no-restricted-syntax
      console.debug(
        `Unknown error format ${JSON.stringify(
          this.field?.errors
        )}, returning field.error.unexpected`
      );
    }
    return 'field.error.unexpected';
  }
}
