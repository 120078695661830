import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

export type InfoPageTemplate =
  | 'imprint'
  | 'terms-of-service'
  | 'privacy-terms'
  | 'system-requirements'
  | 'contact-sdv'
  | 'consent-proctoring-sdv';

@Component({
  selector: 'app-info-page-dialog',
  templateUrl: './info-page-dialog.component.html',
  styleUrls: ['./info-page-dialog.component.scss'],
})
export class InfoPageDialogComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA)
    public template: InfoPageTemplate
  ) {}
}
